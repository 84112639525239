import Swiper, { Autoplay, EffectFade } from 'swiper';
import SimpleBar from 'simplebar';

let solutionsAccordionSwiperWr = document.querySelectorAll(
  '.solutions-accordion-swiper-wr'
);
solutionsAccordionSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, EffectFade],
      observer: true,
      observeParents: true,
      slidesPerView: 1,
      autoHeight: false,
      spaceBetween: 0,
      speed: 750,
      effect: 'fade',
      allowTouchMove: false,
    });

    const solutionsTabs = document.querySelectorAll('.solutions-accordion-tab');

    solutionsTabs.forEach((tab, index) => {
      tab.addEventListener('click', function () {
        if (!this.classList.contains('active')) {
          solutionsTabs.forEach((tab) => tab.classList.remove('active'));

          this.classList.add('active');

          swiper.slideTo(index, 750);

          if (window.innerWidth <= 768) {
            const contentElement = el.querySelector(
              '.solutions-accordion-card-content'
            );
            if (contentElement) {
              setTimeout(() => {
                contentElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                });
              }, 100);
            }
          }
        }
      });
    });
  }
});

document.addEventListener('DOMContentLoaded', function () {
  setTimeout(() => {
    const solutionCards = document.querySelectorAll(
      '.solutions-accordion-card'
    );

    solutionCards.forEach((card) => {
      const readMoreBtn = card.querySelector(
        '.solutions-accordion-card-content__read-more'
      );
      const descriptionDiv = card.querySelector(
        '.solutions-accordion-card-content__descr'
      );

      if (readMoreBtn && descriptionDiv) {
        readMoreBtn.addEventListener('click', function () {
          descriptionDiv.style.display = 'flex';
          descriptionDiv.style.webkitLineClamp = 'unset';
          descriptionDiv.style.webkitBoxOrient = 'unset';
          descriptionDiv.style.textOverflow = 'unset';
          descriptionDiv.style.overflow = 'auto';

          descriptionDiv.style.overflowX = 'hidden';
          descriptionDiv.style.wordWrap = 'break-word';
          descriptionDiv.style.whiteSpace = 'normal';

          readMoreBtn.style.transition = 'opacity 0.3s';
          readMoreBtn.style.opacity = '0';

          setTimeout(() => {
            readMoreBtn.remove();
          }, 300);

          new SimpleBar(descriptionDiv, {
            autoHide: false,
            direction: 'rtl',
            scrollbarMaxSize: 100,
            classNames: {
              content: 'simplebar-content',
              scrollContent: 'simplebar-scroll-content',
              scrollbar: 'simplebar-scrollbar',
              track: 'simplebar-track',
            },
          });

          const simpleBarContent =
            descriptionDiv.querySelector('.simplebar-content');
          if (simpleBarContent) {
            simpleBarContent.style.overflowX = 'hidden';
            simpleBarContent.style.width = '100%';
          }
        });
      }
    });
  }, 500);
});
