import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let solutionsSwiperWr = document.querySelectorAll('.solutions-swiper-wr');
solutionsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Pagination, EffectFade],
      observer: true,
      observeParents: true,
      slidesPerView: 1.05,
      autoHeight: false,
      spaceBetween: 0,
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      breakpoints: {
        551: {
          slidesPerView: 2.05,
        },
        1024: {
          slidesPerView: 3,
        },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      allowTouchMove: true,
    });

    swiperObserver(swiper);
  }
});
