const contactAccordions = document.querySelectorAll(
  '.contact-us-accordion-top'
);

contactAccordions.forEach((accordion) => {
  accordion.addEventListener('click', function () {
    if (!this.parentElement.classList.contains('active')) {
      contactAccordions.forEach((acc) => {
        acc.parentElement.classList.remove('active');
      });
    }

    this.parentElement.classList.toggle('active');
  });
});
