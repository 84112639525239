const searchAccordions = document.querySelectorAll(
  '.search-result-filter-accordion-top'
);

function applyActiveClass() {
  if (window.innerWidth <= 768) {
    const firstAccordion = searchAccordions[0];
    if (firstAccordion) {
      firstAccordion.parentElement.classList.add('active');
    }
  } else {
    searchAccordions.forEach((accordion) => {
      accordion.parentElement.classList.add('active');
    });
  }
}

searchAccordions.forEach((accordion) => {
  accordion.addEventListener('click', function () {
    this.parentElement.classList.toggle('active');
  });
});

applyActiveClass();

window.addEventListener('resize', applyActiveClass);
