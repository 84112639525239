import Choices from 'choices.js';

import './components/header.component.js';

import './utils/vh.utils.js';
import './utils/animate-on-view.utils';

import './components/uploadFile.component.js';
import './components/inputs.component.js';

import './libs/countUp.lib.js';

/* SWIPERS */
import './components/swiper-solutions.component.js';
import './components/swiper-industries.component.js';
import './components/swiper-team.component.js';
import './components/swiper-studies.component.js';
import './components/swiper-solutions-accordion.component.js';
import './components/swiper-blogs.component.js';
import './components/swiper-similar-jobs.component.js';

/* ACCORDIONS */
import './components/contact-us-accordion.component.js';
import './components/search-jobs-accordion.component.js';

/* TITLE ANIMATIONS */
const observerOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1,
};

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const words = entry.target.querySelectorAll('.word');
      words.forEach((word) => (word.style.animationPlayState = 'running'));
      observer.unobserve(entry.target);
    }
  });
}, observerOptions);

document.querySelectorAll('.mask').forEach((mask) => {
  const words = mask.querySelectorAll('.word');
  words.forEach((word) => (word.style.animationPlayState = 'paused'));
  observer.observe(mask);
});

/* SELECTS */
const elementChoices = document.querySelectorAll('.js-choice');

elementChoices.forEach((choices) => {
  const renderedChoise = new Choices(choices, {
    searchEnabled: false,
    itemSelectText: '',
  });
});

/* FILTERS */
const searchResultFilters = document.querySelectorAll(
  '.section-search-results-filters-bottom__item'
);

searchResultFilters.forEach((searchFilter) => {
  searchFilter.addEventListener('click', function () {
    searchResultFilters.forEach((filter) => {
      filter.classList.remove('active');
    });
    this.classList.add('active');
  });
});

document
  .querySelectorAll('.section-jobs-search-result .pagination .pagination__item')
  .forEach((item) => {
    item.addEventListener('click', function (e) {
      e.preventDefault();

      const section = document.querySelector('.section-jobs-search-result');
      const sectionTop =
        section.getBoundingClientRect().top + window.pageYOffset;

      setTimeout(() => {
        window.scrollTo({
          top: sectionTop,
          behavior: 'smooth',
        });
      }, 100);
    });
  });

/* CONTENT ELEMENTS HEADLINE COUNTER */
const postDetailsHeadlines = document.querySelectorAll(
  '.section-post-details .content-element h3'
);

postDetailsHeadlines.forEach((headline, i) => {
  const number = i + 1;
  const currentCount = number < 10 ? `0${number}` : number.toString();

  const countCircleContainer = document.createElement('div');
  countCircleContainer.classList.add('headline-number');

  const countCircle = document.createElement('span');
  countCircle.textContent = currentCount;

  countCircleContainer.appendChild(countCircle);
  headline.prepend(countCircleContainer);
});

/* Enfore number pattern for forms */
document.querySelectorAll('input[type="tel"]').forEach((input) => {
  input.addEventListener('input', function () {
    this.value = this.value.replace(/[^0-9+]/g, '');
    if (this.value.length > 1 && this.value.indexOf('+') !== 0) {
      this.value = this.value.replace('+', '');
    }
  });
});

const jobDescription = document.querySelector('.section-job-description');
if (jobDescription) {
  const jobContent = jobDescription.querySelector('.content-element');

  if (jobContent) {
    jobContent.querySelectorAll('title, style, meta').forEach(tag => {
      tag.remove();
    });

    jobContent.querySelectorAll('*').forEach(child => {
      if (child.hasAttribute('style')) {
        child.removeAttribute('style');
      }
    });
  }
}
